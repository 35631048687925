import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import AboutUs from './Components/AboutUs/AboutUs';
import Courses from './Components/Courses/Courses';
import Footer from './Components/Footer/Footer';
import ClientForm from './Components/Forms/ClientForm';
import Home from './Components/Home/Home';
import Testimonials from './Components/Testimonials/Testimonials';
import WorkWithUs from './Components/Forms/WorkWithUs'

function App() {
  return (
    <Router>
      <div className="overflow-hidden">
        <Routes>
          <Route path="/" element={
            <>
              <div id="home">
                <Home />
              </div>
              <div id="about-us">
                <AboutUs />
              </div>
              <div id="courses">
                <Courses />
              </div>
              <div id="testimonials">
                <Testimonials />
              </div>
              <Footer />
            </>
          } />
          <Route path="/join-us" element={<ClientForm />} />
          <Route path="/work-with-us" element={<WorkWithUs />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
