import React from 'react';
import NavBar from '../NavBar/NavBar';
import Logo from '../Home/HeroLogo.png';
import TimeLine from '../TimeLine/TimeLine';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpotify } from '@fortawesome/free-brands-svg-icons';

function Home() {
  return (
    <>
      <div className='h-screen overflow-hidden flex flex-col bg-[#E7D3B7] relative'>
        <div className='left-[3%] top-[40%] hidden absolute md:block'>
          <TimeLine />
        </div>
        <div className='h-11 hidden md:block'></div>
        <NavBar />
        <div className="hero flex-grow bg-[#E7D3B7]">   
          <div className="hero-content text-center">
            <div className="max-w-md">
              <div>
                <img src={Logo} alt="" />
              </div>
              <p className="pb-6 text-2xl font-bold text-[#4B2E19] roboto-mono-500">
                Linguistics Courses
              </p>
              <div className='roboto-mono-600'>
                <button className="mx-auto mb-4 btn bg-[#C2763F] text-[#F5E3CA] font-bold border-none px-6 py-3">
                  <a href="https://open.spotify.com/show/7CgvQOXUN5lGbR4tMAriKq?si=LQUoTBvdTgKJaPaZnC98Xg&nd=1&dlsi=8b28dab7b0f849fb" target="_blank" rel="noopener noreferrer">
                    Listen to Our Podcast <FontAwesomeIcon icon={faSpotify} size="lg" className='icon-hover pl-2' />
                  </a>
                </button>
                {/* Update the Learn More button to scroll to Courses */}
                <a href="#courses" className="mx-4 btn btn-outline text-[#C2763F] font-bold border-[#C2763F] border-[3px] hover:bg-black hover:text-[#F5E3CA] hover:border-black">
                  Learn More
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Home;
