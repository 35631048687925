import React from 'react';
import Logo from '../Home/HeroLogo.png'; // Update this path if necessary

function WorkWithUs() {
  const emailAddress = 'avoicefromegypt0@gmail.com'; // Your email address

  const handleClick = () => {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;

    // Detect Android or iOS
    if (/android/i.test(userAgent)) {
      // Android-specific logic to open Gmail app
      const emailIntent = `intent://sendemail?to=${emailAddress}&subject=Work%20Application&body=Please%20include%20the%20following%20information:%0A%0A- Full Name%0A- Email Address%0A- Phone Number%0A- Resume/CV#Intent;scheme=mailto;package=com.google.android.gm;end`;
      window.location.href = emailIntent;
    } else if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      // iOS-specific logic to open Mail app
      window.location.href = `message://?to=${emailAddress}&subject=Work%20Application&body=Please%20include%20the%20following%20information:%0A%0A- Full Name%0A- Email Address%0A- Phone Number%0A- Resume/CV`;
    } else {
      // Fallback for desktop or unsupported devices
      window.open(`https://mail.google.com/mail/?view=cm&fs=1&to=${emailAddress}&su=Work%20Application&body=Please%20include%20the%20following%20information:%0A%0A- Full Name%0A- Email Address%0A- Phone Number%0A- Resume/CV`, '_blank');
    }
  };

  return (
    <div className='bg-[#E7D3B7] grid grid-cols-1 md:grid-cols-2 h-screen roboto-mono-700'>
      {/* Left Side */}
      <div className='flex items-center justify-center p-5 md:p-10'>
        <div className='bg-[#F5E3CA] p-6 md:p-8 rounded-2xl object-shadow w-full max-w-lg'>
          <h2 className='text-black text-3xl md:text-4xl kg-red-hands font-semibold text-center'>Work With Us</h2>
          <p className='text-center text-lg md:text-xl text-[#4B2E19] py-4'>
            We are excited to hear from you! Please provide the required information to apply.
          </p>
          <ul className='list-disc pl-5 text-lg md:text-xl text-[#5D2700] font-medium'>
            <li className='py-1'>Full Name</li>
            <li className='py-1'>Email Address</li>
            <li className='py-1'>Phone Number</li>
            <li className='py-1'>Resume/CV</li>
          </ul>
          <div className='flex justify-center mt-6'>
            <button 
              onClick={handleClick} 
              className='bg-[#C2763F] text-[#F5E3CA] font-bold p-2 rounded hover:bg-[#4B2E19] transition duration-300'
            >
              Contact Us via Gmail
            </button>
          </div>
        </div>
      </div>

      {/* Right Side - Logo Only */}
      <div className='hidden md:flex items-center justify-center'>
        <img src={Logo} alt="Logo" className="max-w-md" /> {/* Adjust max-w if needed */}
      </div>
    </div>
  );
}

export default WorkWithUs;
