import React from 'react';
import Logo from '../NavBar/logo-2.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpotify, faFacebook, faYoutube, faTiktok, faInstagram, faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import { Link } from 'react-router-dom';

function NavBar() {
  return (
    <div className="navbar bg-[#F5E3CA] py-0 px-6">
      <div className="navbar-start">
        <div className="dropdown">
          <div tabIndex={0} role="button" className="btn btn-ghost text-black lg:hidden">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-5 w-5"
              fill="black"
              viewBox="0 0 24 24"
              stroke="currentColor">
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M4 6h16M4 12h8m-8 6h16" />
            </svg>
          </div>
          <ul
            tabIndex={0}
            className="menu menu-sm dropdown-content bg-[#F5E3CA] rounded-box z-[1] mt-3 w-52 p-5 font-bold shadow text-black">
            <li><a href='#about-us'>About Us</a></li>
            <li className='py-2'><a href="#courses">Course Details</a></li>
            <li><a href='#testimonials'>Testimonials</a></li>
            <li className='pt-2'>
              <a>Social Media</a>
              <ul className="p-2">
                <li className='py-2'>
                  <a href="https://www.instagram.com/avoicefromegypt/" target="_blank" rel="noopener noreferrer">
                    <FontAwesomeIcon icon={faInstagram} size="xl" className='icon-hover' /> Instagram
                  </a>
                </li>
                <li className='py-2'>
                  <a href="https://www.tiktok.com/@avoicefromegypt?_t=8oUNbUgUWWN&_r=1" target="_blank" rel="noopener noreferrer">
                    <FontAwesomeIcon icon={faTiktok} size="xl" className='icon-hover' /> TikTok
                  </a>
                </li>
                <li className='py-2'>
                  <a href="https://open.spotify.com/show/7CgvQOXUN5lGbR4tMAriKq?si=LQUoTBvdTgKJaPaZnC98Xg&nd=1&dlsi=8b28dab7b0f849fb" target="_blank" rel="noopener noreferrer">
                    <FontAwesomeIcon icon={faSpotify} size="xl" className='icon-hover' /> Spotify
                  </a>
                </li>
                <li className='py-2'>
                  <a href="https://www.facebook.com/profile.php?id=61566008936578&mibextid=LQQJ4d" target="_blank" rel="noopener noreferrer">
                    <FontAwesomeIcon icon={faFacebook} size="xl" className='icon-hover' /> Facebook
                  </a>
                </li>
                <li className='py-2'>
                  <a href="https://youtube.com/@hamsaabbass?si=zDOWfbN401HA7bRC" target="_blank" rel="noopener noreferrer">
                    <FontAwesomeIcon icon={faYoutube} size="xl" className='icon-hover' /> YouTube
                  </a>
                </li>
              </ul>
            </li>
          </ul>
        </div>
        <a className="">
          <div className="">
            <img src={Logo} alt="" className="h-16 bg-transparent" />
          </div>
        </a>
      </div>
      <div className="navbar-center hidden lg:flex text-black">
        <ul className="menu menu-horizontal px-1 text-l roboto-mono-700">
          <li><a href="#about-us" className='hover:bg-transparent nav-stats nav-item'>About Us </a></li>
          <li className='mx-[4rem]'><a href="#courses" className='hover:bg-transparent nav-stats nav-item'>Course Details</a></li>
          <li><a href="#testimonials" className='hover:bg-transparent nav-stats nav-item'>Testimonials</a></li>
        </ul>
      </div>
      <div className="navbar-end roboto-mono-700">
        <Link to="/work-with-us">
          <button className="btn bg-[#C2763F] border-none text-[#F5E3CA]">Work with Us</button>
        </Link>
      </div>
    </div>
  );
}

export default NavBar;
