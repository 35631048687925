import React from 'react'
import Carousel from './Carousel'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faInstagram } from '@fortawesome/free-brands-svg-icons';
import { Link } from 'react-router-dom';


function Testimonials() {
  return (
    <div className=' bg-[#E7D3B7] p-10 flex flex-wrap justify-center content-center text-center roboto-mono-700'>
        <div>
            <h3 className='font-medium text-[#C2763F] text-4xl pt-4'>Testimonials</h3>
            <h2 className='text-3xl md:text-4xl kg-red-hands font-semibold text-black py-4'>What Our Students Are Saying</h2>
            <p className='text-[#4B2E19] text-lg md:text-xl font-medium pb-8'>See how our course has transformed the learning journey for students<br/> just like you</p>
        </div>
        <div className='w-full mt-8'>
            <Carousel/>
        </div>
        <div>
        <button className="mr-0 sm:mr-2 mt-10 btn bg-[#C2763F] text-[#F5E3CA] font-bold border-none px-6 py-3">
                <a href="https://www.instagram.com/avoicefromegypt/" target="_blank">More Reviews <FontAwesomeIcon icon={faInstagram} size="xl" className='icon-hover pl-2' /></a>
            </button>
            <Link to="/join-us">
  <button className="ml-0 sm:ml-2 mt-10 btn-wide btn btn-outline text-[#C2763F] font-bold border-[#C2763F] border-[3px] hover:bg-black hover:text-[#F5E3CA] hover:border-black">
    Join Us
  </button>
</Link>

        </div>
        <div>
      
        </div>
    </div>
  )
}

export default Testimonials