import React from 'react';

const images = [
  { src: './4.jpg', alt: 'Image 4' },
  { src: './1.jpg', alt: 'Image 1' },
  { src: './5.jpg', alt: 'Image 5' },
  { src: './6.jpg', alt: 'Image 6' },
  { src: './2.jpg', alt: 'Image 2' },
  { src: './3.jpg', alt: 'Image 3' }
];

function Carousel() {
  return (
    <div className='grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4'>
      {images.map((image, index) => (
        <div 
          key={index} 
          className='col-span-1 bg-[#F5E3CA] h-48 w-full p-4 rounded-3xl object-shadow overflow-hidden group hover:h-full transition-all duration-500 ease-in-out'
        >
          <img src={image.src} alt={image.alt} className='w-full h-full object-cover rounded-3xl' />
        </div>
      ))}
    </div>
  );
}

export default Carousel;
