import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpotify, faFacebook, faYoutube, faTiktok, faInstagram, faWhatsapp } from '@fortawesome/free-brands-svg-icons';

function TimeLine() {
  return (
    <div>
      <ul className='text-center text-[#97480F]'>
        <li className='py-2'>
          <a href="https://www.instagram.com/avoicefromegypt/" target="_blank">
            <FontAwesomeIcon icon={faInstagram} size="xl" className='icon-hover'/>
          </a>
        </li>
        <li className='py-2'>
          <a href="https://www.tiktok.com/@avoicefromegypt?_t=8oUNbUgUWWN&_r=1" target="_blank">
            <FontAwesomeIcon icon={faTiktok} size="xl" className='icon-hover'/>
          </a>
        </li>
        <li className='py-2'>
          <a href="https://open.spotify.com/show/7CgvQOXUN5lGbR4tMAriKq?si=LQUoTBvdTgKJaPaZnC98Xg&nd=1&dlsi=8b28dab7b0f849fb" target="_blank">
            <FontAwesomeIcon icon={faSpotify} size="xl" className='icon-hover' />
          </a>
        </li>
        <li className='py-2'>
          <a href="https://www.facebook.com/profile.php?id=61566008936578&mibextid=LQQJ4d" target="_blank">
            <FontAwesomeIcon icon={faFacebook} size="xl" className='icon-hover' />
          </a>
        </li>
        <li className='py-2'>
          <a href="https://youtube.com/@hamsaabbass?si=zDOWfbN401HA7bRC" target="_blank">
            <FontAwesomeIcon icon={faYoutube} size="xl" className='icon-hover' />
          </a>
        </li>
      </ul>
    </div>
  );
}

export default TimeLine;
