import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpotify, faFacebook, faYoutube, faTiktok, faInstagram, faWhatsapp } from '@fortawesome/free-brands-svg-icons';

function Footer() {
  return (
    <footer className="bg-[#F5E3CA] py-8">
      <div className="container mx-auto text-center">
        <div className="flex flex-col md:flex-row justify-between items-center mb-4">
          {/* Navigation Links */}
          <div className="mb-4 md:mb-0">
            <ul className="flex flex-col md:flex-row gap-4 text-[#333] font-semibold">
              <li><a href="#about-us" className="hover:text-[#d88a29]">About Us</a></li>
              <li><a href="#courses" className="hover:text-[#d88a29]">Course Details</a></li>
              <li><a href="#testimonials" className="hover:text-[#d88a29]">Testimonials</a></li>
              <li><a href="work-with-us" className="hover:text-[#d88a29]">Work with Us</a></li>
            </ul>
          </div>

          {/* Social Media Icons */}
            <div className="flex gap-4 text-[#97480F] ">
                <a href="https://www.instagram.com/avoicefromegypt/" target="_blank"><FontAwesomeIcon icon={faInstagram} size="xl" className='icon-hover'/></a>
                <a href="https://www.tiktok.com/@avoicefromegypt?_t=8oUNbUgUWWN&_r=1" target="_blank"><FontAwesomeIcon icon={faTiktok} size="xl" className='icon-hover'/></a>
                <a href="https://open.spotify.com/show/7CgvQOXUN5lGbR4tMAriKq?si=LQUoTBvdTgKJaPaZnC98Xg&nd=1&dlsi=8b28dab7b0f849fb" target="_blank"><FontAwesomeIcon icon={faSpotify} size="xl" className='icon-hover' /></a>
                <a href="https://www.facebook.com/profile.php?id=61566008936578&mibextid=LQQJ4d" target="_blank"><FontAwesomeIcon icon={faFacebook} size="xl" className='icon-hover' /></a>
                <a href="https://youtube.com/@hamsaabbass?si=zDOWfbN401HA7bRC" target="_blank"><FontAwesomeIcon icon={faYoutube} size="xl" className='icon-hover' /></a>
            </div>
        </div>

        {/* Copyright Section */}
        <p className="text-[#333] mt-4">
          &copy; 2024 <span className='font-bold'>A Voice from Egypt.</span> All rights reserved.
        </p>
      </div>
    </footer>
  );
}

export default Footer;
